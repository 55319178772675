import { IonButton } from '@ionic/react'
import { updateEntrance } from 'api/entrance'
import React, { useEffect, useState } from 'react'
import { Marker } from 'react-leaflet'
import { getDistance } from 'utils/map'
import Map, { LocateMarker } from './Map'
import { Geolocation } from '@capacitor/geolocation'
import { getPosition } from 'utils/callout'

const UpdatePositionMapModal: React.FC<{
  onDismiss: () => void
  entranceID: number
  entrancePosition: L.LatLngTuple
}> = ({ onDismiss, entrancePosition, entranceID }) => {
  const [distance, setDistance] = useState<number>(0)

  const _updateEntrance = async () => {
    console.log('updating entrance position')
    const position = await getPosition()
    if (!position) {
      // TODO display error
      return
    }

    updateEntrance(entranceID, {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude
    })
      .then((data: any) => {
        onDismiss()
        // TODO display success
      })
      .catch((err: any) => {
        onDismiss()
        // TODO display error
      })
  }

  const updateDistance = async () => {
    const position = await getPosition()

    if (!position) {
      return
    }

    const distance = Math.round(
      getDistance(
        {
          0: position.coords.latitude,
          1: position.coords.longitude
        },
        entrancePosition
      )
    )

    setDistance(distance)
  }

  useEffect(() => {
    updateDistance()
  }, [])

  return (
    <div>
      <div className="minimap">
        <Map
          scrollWheelZoom={false}
          doubleClickZoom={false}
          dragging={false}
          zoom={17}
          minZoom={17}
          maxZoom={17}>
          <LocateMarker />
          {/* TODO, marker icon */}
          <Marker position={entrancePosition} />
        </Map>
      </div>
      <div className="content">
        <span>
          Souhaitez-vous mettre à jour la position de l'entrée avec vos
          coordonnées ?
        </span>
        <p>Celle-ci se situe actuellement à {distance} mètres de vous.</p>
      </div>
      <IonButton
        className="qr-modal_button"
        fill="clear"
        onClick={_updateEntrance}>
        Mettre à jour
      </IonButton>
      <IonButton
        className="qr-modal_button"
        fill="clear"
        onClick={() => onDismiss()}>
        Annuler
      </IonButton>
    </div>
  )
}

export default UpdatePositionMapModal
