import {
  IonActionSheet,
  IonButton,
  IonCard,
  IonIcon,
  IonImg,
  IonItem,
  IonText,
  IonTextarea
} from '@ionic/react'
import Container from 'layouts/containers/Container'
import React, { useEffect, useState } from 'react'
import {
  Redirect,
  RouteComponentProps,
  useHistory,
  useLocation
} from 'react-router'
import LayoutDefault from 'layouts/LayoutDefault'
import { imageOutline, list, qrCode, trash } from 'ionicons/icons'
import { useSelector } from 'react-redux'
import { RootState } from 'stores/reducers'
import { PhotoInterface, usePhotoGallery } from '../../hooks/usePhotoGallery'
import { Spot, ReportType } from 'interfaces/common'
import { createReport } from 'api/reports'
import { getOngoingSpot } from 'api/spots'
import ReportClose, { getQuitReportUrl } from 'components/report/closeReport'
import store from 'stores/store'
import { setToastMessages } from 'stores/actions/toast'
import BackButton from 'pages/admin/backButton'

const ReportSubmission: React.FC = () => {
  const location = useLocation<{
    fromPath: string
    reportType: ReportType
    spot: Spot
    message?: string
    photos?: Array<PhotoInterface>
    profession?: number
  }>()
  const router = useHistory()
  const reportType = location?.state?.reportType
  const propSpot = location?.state?.spot
  const propPhotos = location?.state?.photos
  const propMessage = location?.state?.message
  const fromPath = location?.state?.fromPath
  const profession = location?.state?.profession
  const [message, setmessage] = useState('')
  const [spot, setspot] = useState<Spot>()
  const { photos, takePhoto, deletePhoto, setPhotos } = usePhotoGallery({compress: true})
  const [photoToDelete, setPhotoToDelete] = useState<PhotoInterface>()
  const [loading, setloading] = useState(false)
  const [error, seterror] = useState(false)
  const professionId = useSelector((state: RootState) => state.profession.id)

  const state = useSelector((state: RootState) => state)

  useEffect(() => {
    console.log(photos)
  }, [photos])

  useEffect(() => {
    if (propPhotos) {
      setPhotos(propPhotos)
    }
    if (propMessage) {
      setmessage(propMessage)
    }
    if (!propSpot) {
      getOngoingSpot()
        .then((spot: any) => {
          setspot(spot)
        })
        .catch(console.log)
    } else {
      setspot(propSpot)
    }
  }, [propMessage, propPhotos, propSpot, setPhotos])

  const handleSubmit = () => {
    if (spot && reportType) {
      submit()
    }
  }

  const submit = () => {
    setloading(true)
    seterror(false)

    // Submit report with currently selected profession
    // const currentJob =
    //   state.user.currentProfession || state.user.professions?.length === 1
    //     ? state.user.professions[0]
    //     : null

    createReport({
      message,
      photos,
      spotId: spot?.id,
      typeId: reportType?.id,
      professionId
    })
      .then(() => {
        // Redirect
        store.dispatch(setToastMessages('Signalement effectué avec succès.'))
        router.push(getQuitReportUrl(state.user, fromPath))
      })
      .catch(() => {
        seterror(true)
        setloading(false)
      })
  }

  const selectSpotByScan = () => {
    router.push('/report/scan', {
      reportType,
      message,
      photos,
      fromPath,
      profession
    })
  }

  const selectSpotByList = () => {
    router.push('/report/list', {
      reportType,
      message,
      photos,
      fromPath,
      profession
    })
  }

  if (error) {
    return (
      <LayoutDefault>
        <Container center>
          <p className="ion-text-center">Une erreur a eu lieu</p>
          <IonButton onClick={() => seterror(false)}>Réessayer</IonButton>
        </Container>
      </LayoutDefault>
    )
  }

  if (!reportType) {
    return (
      <Redirect
        to={{
          pathname: '/report/reportType',
          state: { spot, message, photos, fromPath, profession }
        }}
      />
    )
  }

  return (
    <LayoutDefault>
      <Container paddingTop paddingBottom loading={loading}>
        <ReportClose fromPath={fromPath} />
        {/* <Link
          className="report-back"
          to={{
            pathname: '/report/reportType',
            state: { spot, message, photos, fromPath }
          }}>
          <IonIcon icon={arrowBack} />
          <span>Retour</span>
        </Link> */}
        <BackButton url="/" />
        <h3>{spot ? 'Modifiez' : 'Sélectionnez'} l'emplacement</h3>
        <div className="report-spot-buttons">
          {reportType.qualification !== 'qr_code' && (
            <>
              <div
                className="report-spot-button qr-code"
                onClick={selectSpotByScan}>
                <IonIcon icon={qrCode} />
                <span>
                  Scanner le <br />
                  QR code
                </span>
              </div>
              <div className="report-spot-separator">
                <span>OU</span>
              </div>
            </>
          )}
          <div
            className="report-spot-button list-spot"
            onClick={selectSpotByList}>
            <IonIcon icon={list} />
            <span>
              Choisir dans <br />
              la liste
            </span>
          </div>
        </div>

        {spot && (
          <>
            <h3>Ajoutez des informations complémentaires</h3>
            <IonCard class="thumbnail" color="light" mode="ios">
              <div className="description-content border">
                <IonText color="dark" class="label-admin">
                  Signalement&nbsp;:
                </IonText>
                <IonText class="description" color="dark">
                  {reportType.name}
                </IonText>
              </div>
              <div className="description-content border">
                <IonText color="dark" class="label-admin">
                  Emplacement&nbsp;:
                </IonText>
                <IonText class="description" color="dark">
                  {spot.name}
                </IonText>
              </div>
              <div className="description-content">
                <IonText color="dark" class="label-admin">
                  Commentaire&nbsp;:
                </IonText>
                <IonTextarea
                  autoGrow
                  placeholder="Votre message"
                  value={message}
                  onIonChange={(e) => setmessage(e.detail.value!)}
                />
              </div>
              <div className="description-content">
                <IonText color="dark" class="label-admin" onClick={takePhoto}>
                  <span>Ajouter une photo</span>
                  <IonIcon icon={imageOutline} className="report-image-btn" />
                </IonText>
                {photos.length > 0 ? (
                  <div className="list-img">
                    <IonItem lines="none">
                      <div>
                        {photos.map((photo, index) => (
                          <div key={index} className="image-delete-wrapper">
                            <IonImg
                              src={photo.webviewPath}
                              onClick={() => setPhotoToDelete(photo)}
                              className="ion-margin-vertical"
                            />
                            <span className="image-delete"></span>
                          </div>
                        ))}
                      </div>
                    </IonItem>
                  </div>
                ) : null}

                <IonActionSheet
                  isOpen={!!photoToDelete}
                  buttons={[
                    {
                      text: 'Effacer',
                      role: 'destructive',
                      icon: trash,
                      handler: () => {
                        if (photoToDelete) {
                          deletePhoto(photoToDelete)
                          setPhotoToDelete(undefined)
                        }
                      }
                    }
                  ]}
                  onDidDismiss={() => setPhotoToDelete(undefined)}
                />
              </div>
            </IonCard>
            <IonButton className="full" onClick={handleSubmit}>
              Envoyer
            </IonButton>
          </>
        )}
      </Container>
    </LayoutDefault>
  )
}

export default ReportSubmission
