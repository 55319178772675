import { IonButton, IonModal } from '@ionic/react'
import L from 'leaflet'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  MapContainer,
  TileLayer,
  Marker,
  MapContainerProps,
  useMap,
} from 'react-leaflet'
import { getPosition } from 'utils/callout'
import './map.scss'

const getIcon = (_iconSize: any) => {
  return L.icon({
    iconUrl: require('./pin.png'),
    iconSize: _iconSize
  })
}

export function ClickableMarker({
  position,
  callback,
  data,
  children
}: {
  data?: any
  position: L.LatLngExpression
  callback?(data?: any): void
  children?: any
}) {
  const markerRef = useRef(null)
  const map = useMap()
  const eventHandlers = useMemo(
    () => ({
      click() {
        const marker: any = markerRef.current

        if (marker !== null) {
          map.flyTo(marker.getLatLng())
        }

        if (callback) {
          callback(data)
        }
      }
    }),
    [callback, data, map]
  )

  return (
    <Marker eventHandlers={eventHandlers} position={position} ref={markerRef}>
      {children}
    </Marker>
  )
}

export function LocateMarker() {
  const [position, setPosition] = useState<L.LatLngExpression>([0, 0])
  const markerRef = useRef(null)
  const isMounted = useRef(true)
  
  useEffect(() => {

    const init = async () => { 
      const position = await getPosition();
      if(isMounted.current) {
        // @ts-ignore
        setPosition({lat: position?.coords.latitude, lng: position?.coords.longitude})
      }
    }
    init()
    return(() => {
      isMounted.current = false})
  }, [])

  return (
    <Marker position={position} ref={markerRef} icon={getIcon(40)}>
    </Marker>
  )
}

export default function Map({ children, ...rest }: MapContainerProps) {
  const [map, setMap] = useState<L.Map>()
  const [mounted, setmounted] = useState(false)
  const isMounted = useRef(true)

  const centerMap = async () => {
    const position = await getPosition();

    // @ts-ignore
    map?.setView({lat: position?.coords.latitude, lng: position?.coords.longitude}, 13);
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
    centerMap()
      if(isMounted.current) {
        // You have to invalidate map size in case Map object was created before the div
        map?.invalidateSize()
          setmounted(true)
        }
        return(() => {
          isMounted.current = false})
    }, 250)
    return function cleanup() {
      clearTimeout(timeout)
    }
  }, [map])

  return (
    <MapContainer
      {...rest}
      whenCreated={setMap}
      zoom={13}
      maxZoom={16}
      zoomControl={false}
    >
      {mounted && map && (
<>
      {children}
      {/* <LocateMarker /> */}
      </>
      )}
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?layers=C"
      />
    </MapContainer>
  )
}

export const MapInformationModal: React.FC<{
  visible: boolean
  dismiss?(): void
  route: string
}> = ({ visible, dismiss, route, children }) => {
  return (
    <IonModal
      swipeToClose
      isOpen={visible}
      onDidDismiss={dismiss}
      cssClass="map-information-modal auto-height">
      <div>
        <div className="content">
          {children}
          <div className="button-group">
            <IonButton
              routerLink={route}
              onClick={dismiss}>
              Voir
            </IonButton>
            <IonButton
              fill="clear"
              onClick={dismiss}>
              Annuler
            </IonButton>
          </div>
        </div>
      </div>
    </IonModal>
  )
}
