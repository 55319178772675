import React from 'react'
import { Route, RouteComponentProps, useHistory } from 'react-router-dom'
import { IonRouterOutlet } from '@ionic/react'
import AuthMiddleware from 'middlewares/AuthMiddleware'
import ReportTypeSelection from '../ReportTypeSelection'
import ReportSubmission from '../ReportSubmission'
import QrScanner from '../QrScanner'
import EntranceSelection from '../EntranceSelection'
import AdminBuilding from 'pages/admin/building'
import AdminEntrance from 'pages/admin/entrance'
import AdminGroup from 'pages/admin/group'
import AdminSpot from 'pages/admin/spot'
import ReportProfessionSelection from '../ReportProfessionSelection'
import { useDispatch } from 'react-redux'
import { setProfessionId } from 'stores/actions/profession'

const RouterPages: React.FC<RouteComponentProps> = ({ match }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <IonRouterOutlet>
      <AuthMiddleware>
        <Route
          path={`${match.url}/select`}
          // component={ReportProfessionSelection}
          render={(props) => (
            <ReportProfessionSelection
              {...props}
              onSelect={(id) => {
                if (!id) return
                dispatch(setProfessionId(id))
                history.push(`${match.url}/reportType`)
              }}
            />
          )}
          exact
        />
        <Route
          path={`${match.url}/reportType`}
          component={ReportTypeSelection}
          exact
        />
        <Route path={`${match.url}/form`} component={ReportSubmission} exact />
        <Route path={`${match.url}/scan`} component={QrScanner} exact />
        <Route path={`${match.url}/list`} component={EntranceSelection} exact />
        <Route
          exact
          path={`${match.url}/entrance/:entranceID`}
          component={AdminEntrance}
        />
        <Route
          exact
          path={`${match.url}/spots/:spotID`}
          component={AdminSpot}
        />
        <Route
          exact
          path={`${match.url}/groups/:groupID`}
          component={AdminGroup}
        />
        <Route
          exact
          path={`${match.url}/buildings/:buildingID`}
          component={AdminBuilding}
        />
      </AuthMiddleware>
    </IonRouterOutlet>
  )
}

export default RouterPages
