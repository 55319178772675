import { useState } from 'react'
import { base64FromPath } from '@ionic/react-hooks/filesystem'
import { Directory, Filesystem } from '@capacitor/filesystem'
import {
  CameraResultType,
  CameraSource,
  Photo,
  Camera
} from '@capacitor/camera'
import heic2any from 'heic2any'
import imageCompression from 'browser-image-compression'

export interface PhotoInterface {
  filepath: string
  webviewPath?: string
  base64?: string
}

export function usePhotoGallery({ compress }: { compress?: boolean } = {}) {
  // const { deleteFile, writeFile } = useFilesystem()

  const [photos, setPhotos] = useState<PhotoInterface[]>([])

  const takePhoto = async () => {
    let cameraPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 70,
      height: 720
    })

    if (!cameraPhoto.webPath) {
      return
    }

    const fileName = 'poc-' + new Date().getTime() + '.jpeg'
    if (cameraPhoto.format === 'heic') {
      await convertToJpg(cameraPhoto)
    }

    if (compress) {
      const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1080,
        useWebWorker: true
      }

      const blob = await fetch(cameraPhoto.webPath).then((res) => res.blob())
      const file = new File([blob], 'image.jpeg', {
        type: blob.type
      })

      const compressedFile = await imageCompression(file, options)

      const url = URL.createObjectURL(compressedFile)
      cameraPhoto.webPath = url
      cameraPhoto.format = 'jpeg'
    }

    const savedFileImage = await savePicture(cameraPhoto, fileName)
    const newPhotos = [savedFileImage, ...photos]
    setPhotos(newPhotos)
  }

  const convertToJpg = async (file: any) => {
    return fetch(file.webPath)
      .then((res) => res.blob())
      .then((blob) =>
        heic2any({
          blob,
          toType: 'image/jpeg',
          quality: 1
        })
      )
      .then((conversionResult) => {
        // @ts-ignore
        var url = URL.createObjectURL(conversionResult)
        file.webPath = url
        file.format = 'jpeg'
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const savePicture = async (
    photo: Photo,
    fileName: string
  ): Promise<PhotoInterface> => {
    const base64Data = await base64FromPath(photo.webPath!)
    await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Data
    })

    // Use webPath to display the new image instead of base64 since it's
    // already loaded into memory
    return {
      filepath: fileName,
      webviewPath: photo.webPath,
      // base64: `data:image/jpeg;base64,${base64Data}`
      base64: base64Data
    }
  }

  const deletePhoto = async (photo: PhotoInterface) => {
    // Remove this photo from the Photos reference data array
    const newPhotos = photos.filter((p) => p.filepath !== photo.filepath)

    // delete photo file from filesystem
    const filename = photo.filepath.substr(photo.filepath.lastIndexOf('/') + 1)
    await Filesystem.deleteFile({
      path: filename,
      directory: Directory.Data
    })
    setPhotos(newPhotos)
  }

  return {
    photos,
    takePhoto,
    deletePhoto,
    setPhotos
  }
}
