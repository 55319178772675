type professionState = {
  id?: number
}

const initialState: professionState = {
  id: undefined
}

const ProfessionReducer = (
  state = initialState,
  action: any
): professionState => {
  switch (action.type) {
    case 'SET_PROFESSION':
      return {
        ...state,
        id: action.data
      }

    default:
      return state
  }
}

export default ProfessionReducer
