import { combineReducers } from '@reduxjs/toolkit'
import ThemeReducer from './theme'
import ToastReducer from './toast'
import { UserReducer } from './user'
import ProfessionReducer from './profession'

const rootReducer = combineReducers({
  user: UserReducer,
  toast: ToastReducer,
  theme: ThemeReducer,
  profession: ProfessionReducer
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
